import { useDticketingTranslate } from "./useDticketingTranslate";

export function useGetTranslationByRole(path = null, suffix = true) {
  const { t: translation } = useDticketingTranslate(path, suffix);

  const t = (key, role, option = null) => {
    const translatedValue = role ? translation(`${key}_${role}`, option) : translation(key, option);
    return translatedValue === `${key}_${role}` ? translation(key, option) : translatedValue;
  };

  return { t };
}
