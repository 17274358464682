import React, { useEffect } from 'react';
import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'; 
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classes from './index.module.css';

function padNumber(num, size) {
    let s = num + "";
    while (s.length < size) {
        s = "0" + s;
    }
    return s;
}

const ErrorsSelector = ({ errorsMatrix = [], selectedErrorCoordinates = [], setSelectedErrorCoordinates, tableGroups, visibleGroups, handleVisibleGroups, availableColumnsKeyGroupMap }) => {
    const translationCode = `tickets_wizard${process.env.LOCALES_SUFFIX}`
    const { t } = useTranslation();

    const handleSelectedErrorCoordinatesNavigation = (direction) => {
        const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
        const selectedErrorCoordinatesKeyIndex = erroredCellslMap.indexOf(selectedErrorCoordinatesKey)
        let nextCoordinates = []
        if(direction === "right") {
            const [nextRow, nextCol] = erroredCellslMap[selectedErrorCoordinatesKeyIndex + 1].split(":")
            nextCoordinates = [Number(nextRow), Number(nextCol)]
        } else if (direction === "left") {
            const [nextRow, nextCol] = erroredCellslMap[selectedErrorCoordinatesKeyIndex - 1].split(":")
            nextCoordinates = [Number(nextRow), Number(nextCol)]
        }
        setSelectedErrorCoordinates(nextCoordinates)
    }

    const erroredCellslMap = useMemo(() => {
        let erroredCellslMap = []
        if (errorsMatrix.length > 0) {
            for (let rowIndex = 0; rowIndex < errorsMatrix.length; rowIndex++) {
                const row = errorsMatrix[rowIndex]
                for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
                    const cell = row[cellIndex]
                    if (cell.length > 0) {
                        erroredCellslMap.push(rowIndex + ":" + cellIndex)
                    }
                }
            }
        }
        return erroredCellslMap
    }, [errorsMatrix]);

    useEffect(() => {       
        if (erroredCellslMap.length > 0 && selectedErrorCoordinates.length === 0) {     // Used to set first error cordinates
            const [row, column] = erroredCellslMap.at(0).split(":")
            setSelectedErrorCoordinates([Number(row), Number(column)])
        } else if (erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {  // If selected error is not in the erroredCellslMap -- reset to first error (it happens exclusively on compilation)
            const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
            const selectedErrorCoordinatesKeyIndex = erroredCellslMap.indexOf(selectedErrorCoordinatesKey)
            if (selectedErrorCoordinatesKeyIndex === -1) {
                const [row, column] = erroredCellslMap.at(0).split(":")
                setTimeout(() => {
                    setSelectedErrorCoordinates([Number(row), Number(column)])
                }, 500)
            } else {
                const [row, column] = selectedErrorCoordinates
                const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === column)
                const isTheCurrentErrorVisible = visibleGroups.indexOf(columnGroupData.group) !== -1
                if (!isTheCurrentErrorVisible) {
                    let updatedGroups = tableGroups.map((tableGroup) => tableGroup.key)
                    updatedGroups = updatedGroups.filter((groupKey) => groupKey === columnGroupData.group || visibleGroups.includes(groupKey))
                    handleVisibleGroups(updatedGroups)
                }
            }
        } else if (erroredCellslMap.length === 0 && selectedErrorCoordinates.length !== 0) {
            setSelectedErrorCoordinates([])
        }
    }, [selectedErrorCoordinates, erroredCellslMap]);

    useEffect(() => {
        const [row, column] = selectedErrorCoordinates
        /*
            availableColumnsKeyGroupMap = {
                "column_key": {
                    label: {
                        en: "Column 1",
                        es: "Columna 1"
                    },
                    group: "group1"
                    index: 2
                },
                "column_key2": {
                    label: {
                        en: "Column 2",
                        es: "Columna 2"
                    },
                    group: "group1"
                    index: 3
                },
            }
        */
       // If the column group is not visible, then we find the first visible error in the first visible group
        const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === column)
        if (columnGroupData && visibleGroups.indexOf(columnGroupData.group) === -1) {
            // Find the first visible group with errors
            const firstVisibleGroupWithErrors = visibleGroups.find((group) => {
                return erroredCellslMap.find((coordinates) => {
                    const [row, column] = coordinates.split(":")
                    const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === Number(column))
                    return columnGroupData.group === group
                })
            })
            if (firstVisibleGroupWithErrors) {
                const firstErrorInFirstVisibleGroup = erroredCellslMap.find((coordinates) => {
                    const [row, column] = coordinates.split(":")
                    const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === Number(column))
                    return columnGroupData.group === firstVisibleGroupWithErrors
                })
                if (firstErrorInFirstVisibleGroup) {
                    const [row, column] = firstErrorInFirstVisibleGroup.split(":")
                    setSelectedErrorCoordinates([Number(row), Number(column)])
                }
            }
        }

     }, [visibleGroups, availableColumnsKeyGroupMap]);

    const currentMessage = useMemo(() => {
        let message = ""
        if (errorsMatrix.length > 0 && selectedErrorCoordinates.length > 0) {
            const row = errorsMatrix[selectedErrorCoordinates[0]];
            if (row) {
                message = row[selectedErrorCoordinates[1]].at(0)   // We show the first error for the cell
            }
        }
        return message
    }, [erroredCellslMap, selectedErrorCoordinates]);

    const leftDisabled = useMemo(() => {
        if (errorsMatrix.length > 0 && erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {
            const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
            return erroredCellslMap.at(0) === selectedErrorCoordinatesKey
        }
        return true
    }, [erroredCellslMap, errorsMatrix, selectedErrorCoordinates]);

    const rightDisabled = useMemo(() => {
        if (errorsMatrix.length > 0 && erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {
            const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
            return erroredCellslMap.at(erroredCellslMap.length - 1) === selectedErrorCoordinatesKey
        }
        return true
    }, [erroredCellslMap, errorsMatrix, selectedErrorCoordinates]);

    const errorPositionString = useMemo(() => {
        if (errorsMatrix.length > 0 && erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {
            const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
            const selectedErrorIndex = erroredCellslMap.indexOf(selectedErrorCoordinatesKey)
            return (
                <div className="text-xs font-semibold tracking-wide">
                    {`${padNumber(selectedErrorIndex + 1, 2)} / ${padNumber(erroredCellslMap.length, 2)}`}
                </div>
            )
        }
        return ""
    }, [erroredCellslMap, errorsMatrix, selectedErrorCoordinates]);

    const currentErrorNumber = useMemo(() => {
        if (errorsMatrix.length > 0 && erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {
            const selectedErrorCoordinatesKey = selectedErrorCoordinates[0] + ":" + selectedErrorCoordinates[1]
            const selectedErrorIndex = erroredCellslMap.indexOf(selectedErrorCoordinatesKey)
            return selectedErrorIndex
        }
        return 0
    }, [erroredCellslMap, errorsMatrix, selectedErrorCoordinates]);
    
    const makeTheErrorGlow = useMemo(() => {
        if (errorsMatrix.length > 0 && erroredCellslMap.length > 0 && selectedErrorCoordinates.length > 0) {
            const [row, column] = selectedErrorCoordinates
            const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === column)
            const isTheCurrentErrorVisible = visibleGroups.indexOf(columnGroupData.group) !== -1
            return !isTheCurrentErrorVisible
        }
    }, [visibleGroups, availableColumnsKeyGroupMap, selectedErrorCoordinates, erroredCellslMap]);

    const errorBody = (
        <>
            <div className="flex flex-col justify-between p-4">

                <div className="text-sm font-bold text-red-500">{t(`${translationCode}:errors_selector.title`)}</div>

                <div className='w-[240px]'>
                    <span className='text-sm font-bold'>{t(`${translationCode}:errors_selector.error`) + " "}</span>
                    <span className='text-sm font-bold'>{padNumber(currentErrorNumber + 1, 2) + ": "}</span>
                    <span 
                        className={`break-normal text-xs font-normal  ${makeTheErrorGlow ? `underline cursor-pointer text-red-600 hover:opacity-50 ${classes.glow}` : ''}`} 
                        onClick={() => {
                            if (!makeTheErrorGlow) return
                            const [row, column] = selectedErrorCoordinates
                            const columnGroupData = Object.values(availableColumnsKeyGroupMap).find((value) => value.index === column)
                            const isTheCurrentErrorVisible = visibleGroups.indexOf(columnGroupData.group) !== -1
                            if (!isTheCurrentErrorVisible) {
                                let updatedGroups = tableGroups.map((tableGroup) => tableGroup.key)
                                updatedGroups = updatedGroups.filter((groupKey) => groupKey === columnGroupData.group || visibleGroups.includes(groupKey))
                                handleVisibleGroups(updatedGroups)
                            }
                        }}
                    >{currentMessage}</span>
                </div>


            </div>


            <div className="flex justify-between items-center ml-auto">
                {errorPositionString}
            </div>


            <div className="flex justify-between items-center ml-4 mr-4 space-x-2">
                <div
                    className={`p-2 w-[40px] bg-white rounded-lg cursor-pointer ${leftDisabled ? 'opacity-50 !cursor-not-allowed' : 'hover:bg-zinc-100'}`}
                    onClick={() => {
                        if (!leftDisabled) 
                        handleSelectedErrorCoordinatesNavigation('left')
                    }}
                    disabled={leftDisabled}
                >
                    <ArrowBackIosNewIcon
                        fontSize="medium"
                        className="text-gray-500"
                    />
                </div>

                <div
                    className={`p-2 w-[40px] bg-white rounded-lg cursor-pointer ${rightDisabled ? 'opacity-50 !cursor-not-allowed' : 'hover:bg-zinc-100'}`}
                    onClick={() => {
                        if (!rightDisabled)
                        handleSelectedErrorCoordinatesNavigation('right')
                    }}
                    disabled={rightDisabled}
                >
                    <ArrowForwardIosIcon
                        fontSize="medium"
                        className="text-gray-500"
                    />
                </div>
            </div>
        </>
    )
    const successBody = (
        <div className='pl-4 text-sm font-bold text-green-800'>
            {t(`${translationCode}:errors_selector.no_errors`)}
        </div>
    )
    const successContainerStyles = "bg-green-100 border-green-200";
    const errorContainerStyles = "bg-red-100 border-red-400";

    return (
        <div className={`mb-6 w-[480px] h-[120px] py-4 px-2 flex justify-between items-center border-4 rounded-lg ${erroredCellslMap.length > 0 ? errorContainerStyles : successContainerStyles}`}>
            {erroredCellslMap.length > 0 ? errorBody : successBody}
        </div>
    );
};

export { ErrorsSelector };